import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { Order }            from "Utils/API";



// The initial State
const initialState = {
    loading                 : true,
    charging                : false,
    error                   : false,
    edition                 : 0,
    canCreate               : false,
    canEdit                 : false,
    canFilter               : false,
    canImport               : false,
    canExport               : false,
    list                    : [],
    total                   : 0,
    elem                    : {},
    orders                  : [],
    states                  : [],
    products                : [],
    transactions            : [],
    logs                    : [],
    messages                : [],
    actions                 : [],
    chatItems               : [],
    stateGroups             : [],
    stateTypes              : [],
    stateTypesPerGroup      : {},
    retirementTypes         : [],
    pharmacies              : [],
    subsidiaries            : [],
    subsidiariesPerPharmacy : {},
    rejectReasons           : [],
    cancelReasons           : [],
    saleTypes               : [],
    filters                 : {},
    hasFilters              : false,
    sort                    : {
        filter   : "sales",
        orderBy  : "lastStateTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "ORDER_LOADING" });
    },

    /**
     * Fetches the Order List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        if (type === "PHARMACY") {
            params.pharmacyID = elemID;
        } else if (type === "SUBSIDIARY") {
            params.subsidiaryID = elemID;
        }

        const data   = await Order.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "ORDER_LIST", data });
    },

    /**
     * Fetches a single Order
     * @param {Function} dispatch
     * @param {Number}   orderID
     * @param {Boolean=} withLoading
     * @returns {Promise}
     */
    async fetchElem(dispatch, orderID, withLoading = true) {
        if (withLoading) {
            dispatch({ type : "ORDER_CHARGING" });
        }
        const data = await Order.getOne({ orderID });
        dispatch({ type : "ORDER_ELEM", data });
    },

    /**
     * Fetches the Order Filter data
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchFilterData(dispatch) {
        const data = await Order.getFilterData();
        dispatch({ type : "ORDER_FILTER", data });
    },

    /**
     * Activates an Order
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    activateElem(dispatch, params) {
        return Order.activate(params);
    },

    /**
     * Retires an Order
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    retiredElem(dispatch, params) {
        return Order.retired(params);
    },

    /**
     * Delivers an Order
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    deliveredElem(dispatch, params) {
        return Order.delivered(params);
    },

    /**
     * Cancels an Order
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    cancelElem(dispatch, params) {
        return Order.cancel(params);
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdTimeDate      = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.lastStateTimeDate    = DateTime.formatDate(elem.lastStateTime, "dashesTime");
    elem.activationTimeText   = DateTime.formatTime(elem.activationTime);
    elem.notificationTimeText = DateTime.formatTime(elem.notificationTime);
    elem.expirationTimeText   = DateTime.formatTime(elem.expirationTime);
    elem.name                 = NLS.format("ORDERS_ITS_NAME", elem.orderNumber);
    elem.numberText           = `#${elem.orderNumber}`;
    elem.productText          = NLS.pluralize("ORDERS_PRODUCTS", elem.totalProducts);
    elem.discountTotalText    = Utils.formatPrice(elem.discountTotal);
    elem.grossTotalText       = Utils.formatPrice(elem.grossTotal);
    elem.billingTotalText     = Utils.formatPrice(elem.billingTotal);
    elem.totalToPayText       = Utils.formatPrice(elem.totalToPay);
    elem.netTotalText         = Utils.formatPrice(elem.netTotal);
    elem.logisticPriceText    = Utils.formatPrice(elem.logisticPrice);
    elem.logisticFeePercent   = Utils.formatNumber(elem.logisticFeePercent, 2);
    elem.logisticFeeText      = `${Utils.formatPrice(elem.logisticFee)} <small>(${elem.logisticFeePercent}%)</small>`;
    elem.commissionPercent    = Utils.formatNumber(elem.commissionPercent, 2);
    elem.commissionText       = `${Utils.formatPrice(elem.commission)} <small>(${elem.commissionPercent}%)</small>`;
    elem.benvidaFeeText       = Utils.formatPrice(elem.benvidaFee);
    elem.benvidaFeePercent    = Utils.formatNumber(elem.benvidaFeePercent, 2);
    elem.benvidaFeeFull       = `${elem.benvidaFeeText} <small>(${elem.benvidaFeePercent}%)</small>`;
    elem.mercadoPagoText      = Utils.formatPrice(elem.mercadoPagoFee);
    elem.mercadoPagoPercent   = Utils.formatNumber(elem.mercadoPagoPercent, 2);
    elem.mercadoPagoFull      = `${elem.mercadoPagoText} <small>(${elem.mercadoPagoPercent}%)</small>`;
    elem.clientBirthText      = elem.clientBirthDate.split("-").reverse().join("/");
    elem.clientAge            = DateTime.fromString(elem.clientBirthDate).getAge();

    if (elem.npsImprovements) {
        elem.npsText = `<b>${elem.npsRecommendation}</b> - ${elem.npsImprovements}`;
    } else if (elem.npsRecommendation) {
        elem.npsText = elem.npsRecommendation;
    }

    return elem;
}

/**
 * Parses a single State
 * @param {Object} elem
 * @returns {Object}
 */
function parseState(elem) {
    elem.createdTimeDate = DateTime.formatDate(elem.createdTime, "dashesTime");
    return elem;
}

/**
 * Parses a single Product
 * @param {Object} elem
 * @returns {Object}
 */
function parseProduct(elem) {
    elem.productTypeName   = NLS.get("SELECT_PRODUCT_TYPES", elem.productType);
    elem.originText        = NLS.get("SELECT_DISCOUNT_ORIGINS", elem.discountOrigin);
    elem.priceText         = `${Utils.formatPrice(elem.price)}`;
    elem.subTotalText      = `${Utils.formatPrice(elem.subTotal)}`;
    elem.discountTotalText = `${Utils.formatPrice(elem.discountTotal)}`;
    elem.totalText         = `${Utils.formatPrice(elem.total)}`;
    elem.benvidaFeeText    = `${Utils.formatPrice(elem.benvidaFee)} <small>(${elem.benvidaFeePercent}%)</small>`;
    return elem;
}

/**
 * Parses a single Transaction
 * @param {Object} elem
 * @returns {Object}
 */
function parseTransaction(elem) {
    elem.settlementDate = DateTime.formatDate(elem.settlementTime, "dashesTime");
    elem.commissionText = `${Utils.formatPrice(elem.commission)}`;
    return elem;
}

/**
 * Parses a single Log
 * @param {Object} elem
 * @returns {Object}
 */
function parseLog(elem) {
    elem.createdDate = DateTime.formatDate(elem.createdTime, "dashesSeconds");
    elem.errorClass  = elem.isError ? "text-red" : "text-green";
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ORDER_LIST", "ORDER_ELEM")) {
        return { ...state, loading : false, charging : false, error : true };
    }

    switch (action.type) {
    case "ORDER_LOADING":
        return {
            ...state,
            loading                 : true,
        };

    case "ORDER_CHARGING":
        return {
            ...state,
            charging                : true,
        };

    case "ORDER_LIST":
        return {
            ...state,
            loading                 : false,
            error                   : false,
            canCreate               : action.data.canCreate,
            canEdit                 : action.data.canEdit,
            canFilter               : action.data.canFilter,
            canImport               : action.data.canImport,
            canExport               : action.data.canExport,
            list                    : Utils.parseList(action.data.list, parseElem),
            total                   : action.data.total,
            filters                 : action.data.filters,
            hasFilters              : !Utils.isEmpty(action.data.filters),
            sort                    : action.data.sort,
        };

    case "ORDER_ELEM":
        return {
            ...state,
            charging                : false,
            error                   : false,
            edition                 : state.edition + 1,
            elem                    : parseElem(action.data.elem),
            orders                  : Utils.parseList(action.data.orders, parseElem),
            states                  : Utils.parseList(action.data.states, parseState),
            products                : Utils.parseList(action.data.products, parseProduct),
            transactions            : Utils.parseList(action.data.transactions, parseTransaction),
            logs                    : Utils.parseList(action.data.logs, parseLog),
            messages                : action.data.messages,
            actions                 : action.data.actions,
            chatItems               : Commons.parseMessages(action.data.messages, action.data.actions),
            cancelReasons           : action.data.cancelReasons,
            saleTypes               : action.data.saleTypes,
        };

    case "ORDER_FILTER":
        return {
            ...state,
            error                   : false,
            edition                 : state.edition + 1,
            stateGroups             : action.data.stateGroups,
            stateTypes              : action.data.stateTypes,
            stateTypesPerGroup      : action.data.stateTypesPerGroup,
            retirementTypes         : action.data.retirementTypes,
            pharmacies              : action.data.pharmacies,
            subsidiaries            : action.data.subsidiaries,
            subsidiariesPerPharmacy : action.data.subsidiariesPerPharmacy,
            rejectReasons           : action.data.rejectReasons,
            cancelReasons           : action.data.cancelReasons,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
