import React                from "react";
import PropTypes            from "prop-types";
import { Doughnut }         from "react-chartjs-2";
import Commons              from "Utils/Commons";

// Components
import DashboardContainer   from "../Components/DashboardContainer";
import DashboardReport      from "../Components/DashboardReport";
import DashboardTable       from "../Components/DashboardTable";



/**
 * The Dashboard Doughnut Chart
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DoughnutChart(props) {
    const { title, tooltip, field, data, noneMessage, isPrice, children } = props;


    // Create the Datasets
    const datasets = React.useMemo(() => {
        data.sort((a, b) => b[field] - a[field]);
        return [{
            data            : Commons.getValueList(data, field, 10),
            backgroundColor : Commons.getColorList(data, 10),
        }];
    }, [ JSON.stringify(data), field ]);


    // Do the Render
    if (!data.length) {
        return <React.Fragment />;
    }
    return <DashboardContainer>
        <DashboardReport message={title} tooltip={tooltip}>
            <Doughnut
                options={{
                    maintainAspectRatio : false,
                    plugins : {
                        legend  : Commons.getLegendOptions("right"),
                        tooltip : Commons.getTooltipOptions(isPrice),
                    },
                }}
                data={{
                    labels   : Commons.getLabelList(data, noneMessage, 0, 10),
                    datasets : datasets,
                }}
            />
        </DashboardReport>

        <DashboardTable>
            {children}
        </DashboardTable>
    </DashboardContainer>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DoughnutChart.propTypes = {
    title       : PropTypes.string.isRequired,
    tooltip     : PropTypes.string,
    noneMessage : PropTypes.string,
    field       : PropTypes.string.isRequired,
    data        : PropTypes.array.isRequired,
    isPrice     : PropTypes.bool,
    children    : PropTypes.any,
};

export default DoughnutChart;
