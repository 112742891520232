import React                from "react";
import Store                from "Dashboard/Core/Store";
import Commons              from "Utils/Commons";

// Components
import PolarAreaChart       from "../../Utils/Charts/PolarAreaChart";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



/**
 * The Operations Cancels
 * @returns {React.ReactElement}
 */
function OperationsCancels() {
    const { cancelTotals } = Store.useState("dashboardOperations");


    // Do the Render
    return <PolarAreaChart
        title="DASHBOARD_OPERATIONS_CANCEL_REASON"
        field="totalOrders"
        data={cancelTotals}
    >
        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="name"        message="CANCEL_REASONS_SINGULAR" isFlex isTitle />
                <TableHeader field="totalOrders" message="DASHBOARD_ORDERS"        isFlex maxWidth="120" align="center" />
            </TableHead>
            <TableBody>
                {cancelTotals.map((elem, index) => <TableRow key={elem.name}>
                    <TableCell>
                        <ColorCircle color={Commons.getColor(index)} />
                        {elem.name}
                    </TableCell>
                    <TableCell message={elem.totalOrders} />
                </TableRow>)}
            </TableBody>
        </Table>
    </PolarAreaChart>;
}

export default OperationsCancels;
