import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import OrderGeneral         from "./OrderGeneral";
import OrderClient          from "./OrderClient";
import OrderLogistic        from "./OrderLogistic";
import OrderPayment         from "./OrderPayment";
import OrderStates          from "./OrderStates";
import OrderChat            from "./OrderChat";
import OrderChildren        from "./OrderChildren";
import OrderProducts        from "./OrderProducts";
import OrderTransactions    from "./OrderTransactions";
import OrderLogs            from "./OrderLogs";
import OrderRecipes         from "./OrderRecipes";

// Dashboard
import Content              from "Dashboard/Components/Core/Content";
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";



// Styles
const Container = Styled(Content)`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Info = Styled.div.attrs(({ hasStates }) => ({ hasStates }))`
    display: grid;
    gap: 16px;

    ${(props) => props.hasStates ? `
        grid-template-areas:
            "general client states chat"
            "logistic payment states chat";
        grid-template-columns: 1fr 1fr 260px 260px;
    ` : `
        grid-template-areas:
            "general client"
            "logistic payment";
        grid-template-columns: 1fr 1fr;
    `}

    @media screen and (max-width: 1100px) {
        ${(props) => props.hasStates ? `
            grid-template-areas:
                "general client"
                "logistic payment"
                "states chat";
            grid-template-columns: 1fr 1fr;
        ` : `
            grid-template-areas:
                "general client"
                "logistic payment";
            grid-template-columns: 1fr 1fr;
        `}
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        ${(props) => props.hasStates ? `
            grid-template-areas:
                "general"
                "client"
                "logistic"
                "payment"
                "states"
                "chat";
        ` : `
            grid-template-areas:
                "general"
                "client"
                "logistic"
                "payment";
        `}
    }
`;



/**
 * The Order Content
 * @returns {React.ReactElement}
 */
function OrderContent() {
    const { charging, error, states } = Store.useState("order");


    // Do the Render
    if (error) {
        return <Container>
            <NoneAvailable message="ORDERS_ERROR_EXISTS" />
        </Container>;
    }

    return <Container isLoading={charging}>
        <Info hasStates={states.length > 0}>
            <OrderGeneral />
            <OrderClient />
            <OrderLogistic />
            <OrderPayment />
            <OrderStates />
            <OrderChat />
        </Info>

        <OrderChildren />
        <OrderProducts />
        <OrderTransactions />
        <OrderLogs />
        <OrderRecipes />
    </Container>;
}

export default OrderContent;
