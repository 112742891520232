import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Commons              from "Utils/Commons";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--main-gap);
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Title = Styled.h3`
    margin: 0;
    font-size: 14px;
    font-weight: normal;
`;

const Total = Styled.div`
    font-size: 24px;
`;

const Period = Styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: -6px;
`;

const Month = Styled.div`
    display: flex;
    flex-direction: column;

    &:last-of-type {
        align-items: flex-end;
    }

    span {
        font-size: 9px;
        text-transform: uppercase;
        color: var(--darker-gray);
    }
`;



/**
 * The Daily Total
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DailyTotal(props) {
    const { message, property, isPrice } = props;

    const { totals, thisPeriod, lastPeriod } = Store.useState("dashboardDaily");


    // Calculates the percent diff
    const getPercent = (thisTotal, lastTotal) => {
        if (thisTotal && !lastTotal) {
            return "∞%";
        }
        if (!lastTotal) {
            return "0%";
        }
        const diff    = Math.abs(thisTotal - lastTotal);
        const percent = Math.round(diff / lastTotal * 100);
        return `${percent}%`;
    };


    // Calculate the Totals
    const currTotal  = totals[property]     ?? 0;
    const thisTotal  = thisPeriod[property] ?? 0;
    const lastTotal  = lastPeriod[property] ?? 0;
    const isGreater  = thisTotal >= lastTotal;
    const colorClass = isGreater ? "text-green" : "text-red";


    // Do the Render
    return <Container>
        <Title>{NLS.get(message)}</Title>
        <Total>{Commons.formatNumber(currTotal, isPrice)}</Total>
        <Period>
            <Month>
                <span>{NLS.get("DASHBOARD_DAILY_LAST_MONTH")}</span>
                {Commons.formatNumber(lastTotal, isPrice)}
            </Month>
            <div className={colorClass}>
                <Icon icon={isGreater ? "up" : "down"} />
                {getPercent(thisTotal, lastTotal)}
            </div>
            <Month>
                <span>{NLS.get("DASHBOARD_DAILY_THIS_MONTH")}</span>
                {Commons.formatNumber(thisTotal, isPrice)}
            </Month>
        </Period>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DailyTotal.propTypes = {
    message  : PropTypes.string.isRequired,
    property : PropTypes.string.isRequired,
    isPrice  : PropTypes.bool,
};

export default DailyTotal;
