import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Commons              from "Utils/Commons";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.li`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--main-gap);
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Title = Styled.h3`
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
`;

const Total = Styled.div.attrs(({ color }) => ({ color }))`
    font-size: 24px;
    white-space: nowrap;
    ${(props) => props.color && `color: ${props.color};`}
`;

const Help = Styled(Icon)`
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 20px;
    color: var(--primary-color);
`;



/**
 * The Dashboard Total
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardTotal(props) {
    const { isHidden, message, tooltip, value, isPrice, isDiff, isText } = props;


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Container>
        <Title>{NLS.get(message)}</Title>
        <Total color={isDiff ? (value > 0 ? "green" : "red") : ""}>
            {isDiff && <Icon icon={value > 0 ? "up" : "down"} />}
            {isText ? value : Commons.formatNumber(value, isPrice)}
        </Total>
        <Help
            isHidden={!tooltip}
            icon="help"
            tooltip={tooltip}
            tooltipWidth={200}
            tooltipDelay={0.2}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardTotal.propTypes = {
    isHidden : PropTypes.bool,
    message  : PropTypes.string.isRequired,
    tooltip  : PropTypes.string,
    value    : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
    isPrice  : PropTypes.bool,
    isDiff   : PropTypes.bool,
    isText   : PropTypes.bool,
};

export default DashboardTotal;
