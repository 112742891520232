import React                from "react";
import Store                from "Dashboard/Core/Store";
import Commons              from "Utils/Commons";

// Components
import BarChart             from "../../Utils/Charts/BarChart";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableHeader          from "Dashboard/Components/Table/TableHeader";



/**
 * The Commercial Laboratory
 * @returns {React.ReactElement}
 */
function CommercialLaboratory() {
    const { laboratoryTotals } = Store.useState("dashboardCommercial");


    // Do the Render
    return <BarChart
        title="DASHBOARD_COMMERCIAL_LABORATORY"
        tooltip="DASHBOARD_COMMERCIAL_LABORATORY_TP"
        data={laboratoryTotals}
        field="effectiveUnits"
    >
        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="name"           message="MACRO_CATEGORIES_SINGULAR"          isFlex isTitle />
                <TableHeader field="effectiveUnits" message="DASHBOARD_UNITS"                    isFlex maxWidth="70" align="center" />
                <TableHeader field="grossTotal"     message="DASHBOARD_COMMERCIAL_GROSS_BILLING" isFlex maxWidth="120" align="right" />
            </TableHead>
            <TableBody>
                {laboratoryTotals.map((elem) => <TableRow key={elem.name}>
                    <TableCell message={elem.name}            />
                    <TableCell message={elem.effectiveUnits} />
                    <TableCell message={Commons.formatNumber(elem.grossTotal, true)} />
                </TableRow>)}
            </TableBody>
        </Table>
    </BarChart>;
}

export default CommercialLaboratory;
