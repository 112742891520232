import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.div`
    height: var(--document-height);
    overflow: auto;
`;

const DocumentContent = Styled(Html)`
    box-sizing: border-box;
    width: calc(100vw - var(--sidebar-width) - var(--document-list-width) - var(--main-padding)* 2 - var(--main-gap));
    margin: 0 auto;
    padding: 0 var(--main-padding);
    line-height: 1.5;

    h1 {
        font-size: 2em;
    }
    a {
        color: var(--primary-color);
    }
    a:hover {
        text-decoration: none;
    }
    img {
        max-width: 800px;
    }

    table {
        width: 100%;
        border-spacing: 0;
    }
    tr td {
        padding: 8px 12px;
        border-bottom: 1px solid #ebedf2;
        border-right: 1px solid #ebedf2;
    }
    tr:first-child td {
        background: #fafbfd;
        border-top: 1px solid #ebedf2;
    }
    tr td:first-child {
        border-left: 1px solid #ebedf2;
    }
    tr:first-child td:first-child {
        border-top-left-radius: var(--border-radius);
    }
    tr:first-child td:last-child {
        border-top-right-radius: var(--border-radius);
    }
    tr:last-child td:first-child {
        border-bottom-left-radius: var(--border-radius);
    }
    tr:last-child td:last-child {
        border-bottom-right-radius: var(--border-radius);
    }
    tr td p {
        margin: 0;
    }

    pre {
        padding: 16px;
        border: 1px solid #ebedf2;
        border-radius: var(--border-radius);
        background: #fafbfd;
    }

    @media (max-width: 1150px) {
        img {
            max-width: calc(var(--document-view-width) - 2 * var(--main-padding));
            height: auto;
        }
    }
`;



/**
 * The Document View
 * @returns {React.ReactElement}
 */
function DocumentView() {
    const { elem } = Store.useState("document");


    // Add the Files Url
    const content = React.useMemo(() => {
        return Utils.replaceSourceUrls(elem.content);
    }, [ elem.content ]);


    // Do the Render
    if (!elem.content) {
        return <React.Fragment />;
    }
    return <Container>
        <DocumentContent content={content} />
    </Container>;
}

export default DocumentView;
